/* src/components/HotelBanner.css */

.hotel-banner {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* border-radius: 10px; */
  overflow: hidden;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* max-width: 350px;  */
  margin: auto;
  transition: transform 0.2s;
}

.hotel-banner:hover {
  transform: scale(1.02);
}

.hotel-image {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.hotel-info {
  padding: 15px;
}

.hotel-title {
  font-size: 1.5rem;
  margin: 0 0 10px 0;
  color: #333333;
}

.hotel-address {
  font-size: 1rem;
  color: #777777;
}
