body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* HotelCard.css */
.hotel-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  /* width: 100%; */
  /* max-width: 320px; Good for mobile */
  margin: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.hotel-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.hotel-info {
  padding: 10px;
}

.hotel-name {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #333;
}

.hotel-rating {
  color: #ff9800;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.hotel-address {
  font-size: 0.85rem;
  color: #777;
  margin-bottom: 10px;
}

.hotel-price {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* //--- */

.selector-container {
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 90%;
  max-width: 400px; /* Limit the max width for larger screens */
}

.option {
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  border-radius: 8px;
  transition: background 0.3s;
  font-size: 16px; /* Adjust font size for better readability on mobile */
}

.option p {
  margin: 0;
}

.option.veg {
  background-color: #4caf50; /* Green for vegetarian */
  color: white;
}

.option.non-veg {
  background-color: #f44336; /* Red for non-vegetarian */
  color: white;
}

.option.selected {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.option:not(.selected):hover {
  opacity: 0.8; /* Slight opacity change on hover */
}
